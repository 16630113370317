// import axios from 'axios'

export const npsMixin = {
  methods: {

    async livepanelLoadDataRequest ({ geo_id, center_id, campaign_id, wave_id, tab, chart, endpoint }) {

      return await new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        const sessionkey = this.$store.getters.getLoginData.token
        xhr.open('POST', this.$store.getters.getApiUrl, true)
        const xhrRequest = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
          <SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tns="urn:LivePanelIberostarSoapControllerwsdl" xmlns:soap="http://schemas.xmlsoap.org/wsdl/soap/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:wsdl="http://schemas.xmlsoap.org/wsdl/" xmlns:soap-enc="http://schemas.xmlsoap.org/soap/encoding/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"><SOAP-ENV:Body>
          <mns:${endpoint} xmlns:mns="urn:LivePanelIberostarSoapControllerwsdl" SOAP-ENV:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
          <sessionkey xsi:type="xsd:string">${sessionkey}</sessionkey><geo_id xsi:type="xsd:string">${geo_id}</geo_id><center_id xsi:type="xsd:string">${center_id}</center_id><campaign_id xsi:type="xsd:string">${campaign_id}</campaign_id><wave_id xsi:type="xsd:string">${wave_id}</wave_id><answer_type xsi:type="xsd:string">json</answer_type>
          </mns:${endpoint}></SOAP-ENV:Body></SOAP-ENV:Envelope>`
        xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8')
        xhr.onload = async () => {
          if (xhr.status === 200) {
            let response = JSON.parse(xhr.responseXML.getElementsByTagName(`ns1:${endpoint}Response`)[0].textContent)
            response.tab = tab
            response.chart = chart
            resolve(await this.$store.dispatch('setLivepanelData', response))
          } else {
            resolve(xhr.responseXML.getElementsByTagName('SOAP-ENV:Fault')[0].textContent)
          }
        }
        xhr.onerror = () => {
          resolve(undefined)
          this.$router.push('/')
        }
        xhr.send(xhrRequest)
      })
    },

    async livepanelLoadData (payload) {
      // Check if login exists and token is still active
      const hasLoginData = !!this.$store.getters.getLoginData.token
      const expiredToken = this.$store.getters.getTokenTime < new Date().getTime()
      if (!hasLoginData) {
        this.$router.push('/')
      } else if (hasLoginData && expiredToken) {
        const user = this.$store.getters.getLoginData.user
        const pass = this.$store.getters.getLoginData.pass
        await this.livepanelDoLogin(user, pass)
        await this.livepanelLoadDataRequest(payload)
      } else if (hasLoginData && !expiredToken) {
        await this.livepanelLoadDataRequest(payload)
      }
    }
  }
}
