<template>
  <b-row>
    <b-col class="text-center">
      <h3>NPS Global</h3>
      <b-row class="mt-4" no-gutters>
        <b-col class="text-right">
          <h1>{{ chartData.block1.nps }}</h1>
          <h4>TOTAL NPS</h4>
        </b-col>
        <b-col cols="2" class="text-center mt-1">
          <template v-if="chartData.block1.nps < chartData.block1.npsyear">
            <svg
              xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24">
              <path fill="#64dd17" d="M12 0l8 9h-6v15h-4v-15h-6z"/>
            </svg>
          </template>
          <template v-else-if="chartData.block3.nps === chartData.block3.npsyear">
            <svg
              xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24">
              <path fill="#FFA726" d="M0 8h24v4h-24z"/>
              <path fill="#FFA726" d="M0 18h24v4h-24z"/>
            </svg>
          </template>
          <template v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24">
              <path fill="#E5554F" d="M12 24l-8-9h6v-15h4v15h6z"/>
            </svg>
          </template>
          <p
            v-if="chartData.block1.nps < chartData.block1.npsyear"
            style="color:#64dd17;font-weight:bold"
          >
            {{ (((chartData.block1.npsyear - chartData.block1.nps) / chartData.block1.nps) * 100).toFixed(2) + '%'  }}
          </p>
          <p
            v-else-if="chartData.block1.nps > chartData.block1.npsyear"
            style="color:#E5554F;font-weight:bold"
          >
            {{ (((chartData.block1.npsyear - chartData.block1.nps) / chartData.block1.nps) * 100).toFixed(2) + '%'  }}
          </p>
          <p
            v-else
            style="color:#FFA726;font-weight:bold"
          >0%</p>
        </b-col>
        <b-col class="text-left">
          <h1><span>{{ chartData.block1.npsyear }}</span></h1>
          <h4>NPS {{ currentYear }}</h4>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col class="text-center">
          <h3>{{ chartData.block1.surveys }}</h3>
          <h5>Encuestas</h5>
        </b-col>
      </b-row>
      <hr class="mt-4">
      <apexchart
        type="radialBar"
        :options="chartOptions"
        :series="chartData.block1.series"
      />
    </b-col>
    <b-col class="text-center">
      <h3>EMEA</h3>
      <b-row class="mt-4" no-gutters>
        <b-col class="text-right">
          <h1>{{ chartData.block2.nps }}</h1>
          <h4>TOTAL NPS</h4>
        </b-col>
        <b-col cols="2" class="text-center mt-1">
          <template v-if="chartData.block2.nps < chartData.block2.npsyear">
            <svg
              xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24">
              <path fill="#64dd17" d="M12 0l8 9h-6v15h-4v-15h-6z"/>
            </svg>
          </template>
          <template v-else-if="chartData.block2.nps === chartData.block2.npsyear">
            <svg
              xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24">
              <path fill="#FFA726" d="M0 8h24v4h-24z"/>
              <path fill="#FFA726" d="M0 18h24v4h-24z"/>
            </svg>
          </template>
          <template v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24">
              <path fill="#E5554F" d="M12 24l-8-9h6v-15h4v15h6z"/>
            </svg>
          </template>
          <p
            v-if="chartData.block2.nps < chartData.block2.npsyear"
            style="color:#64dd17;font-weight:bold"
          >
            {{ (((chartData.block2.npsyear - chartData.block2.nps) / chartData.block2.nps) * 100).toFixed(2) + '%'  }}
          </p>
          <p
            v-else-if="chartData.block2.nps > chartData.block2.npsyear"
            style="color:#E5554F;font-weight:bold"
          >
            {{ (((chartData.block2.npsyear - chartData.block2.nps) / chartData.block2.nps) * 100).toFixed(2) + '%'  }}
          </p>
          <p
            v-else
            style="color:#FFA726;font-weight:bold"
          >0%</p>
        </b-col>
        <b-col class="text-left">
          <h1><span>{{ chartData.block2.npsyear }}</span></h1>
          <h4 class="mt-2">AÑO {{ currentYear }}</h4>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col class="text-center">
          <h3>{{ chartData.block2.surveys }}</h3>
          <h5>Encuestas</h5>
        </b-col>
      </b-row>
      <hr class="mt-4">
      <apexchart
        type="radialBar"
        :options="chartOptions"
        :series="chartData.block2.series"
      />
    </b-col>
    <b-col class="text-center">
      <h3>América</h3>
      <b-row class="mt-4" no-gutters>
        <b-col class="text-right">
          <h1>{{ chartData.block3.nps }}</h1>
          <h4>TOTAL NPS</h4>
        </b-col>
        <b-col cols="2" class="text-center mt-1">
          <template v-if="chartData.block3.nps < chartData.block3.npsyear">
            <svg
              xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24">
              <path fill="#64dd17" d="M12 0l8 9h-6v15h-4v-15h-6z"/>
            </svg>
          </template>
          <template v-else-if="chartData.block3.nps === chartData.block3.npsyear">
            <svg
              xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24">
              <path fill="#FFA726" d="M0 8h24v4h-24z"/>
              <path fill="#FFA726" d="M0 18h24v4h-24z"/>
            </svg>
          </template>
          <template v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24">
              <path fill="#E5554F" d="M12 24l-8-9h6v-15h4v15h6z"/>
            </svg>
          </template>
          <p
            v-if="chartData.block3.nps < chartData.block3.npsyear"
            style="color:#64dd17;font-weight:bold"
          >
            {{ (((chartData.block3.npsyear - chartData.block3.nps) / chartData.block3.nps) * 100).toFixed(2) + '%'  }}
          </p>
          <p
            v-else-if="chartData.block3.nps > chartData.block3.npsyear"
            style="color:#E5554F;font-weight:bold"
          >
            {{ (((chartData.block3.npsyear - chartData.block3.nps) / chartData.block3.nps) * 100).toFixed(2) + '%'  }}
          </p>
          <p
            v-else
            style="color:#FFA726;font-weight:bold"
          >0%</p>
        </b-col>
        <b-col class="text-left">
          <h1><span>{{ chartData.block3.npsyear }}</span></h1>
          <h4>AÑO {{ currentYear }}</h4>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col class="text-center">
          <h3>{{ chartData.block3.surveys }}</h3>
          <h5>Encuestas</h5>
        </b-col>
      </b-row>
      <hr class="mt-4">
      <apexchart
        type="radialBar"
        :options="chartOptions"
        :series="chartData.block3.series"
      />
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'Chart1',
    props: {
      chartData: {
        type: Object,
        required: true
      },
      chartOptions: {
        type: Object,
        required: true
      }
    },
    computed: {
      currentYear () {
        return new Date().getFullYear()
      }
    }
  }
</script>
