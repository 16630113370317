<template>
  <b-container
    fluid
    class="iberostar"
  >
    <logout />
    <b-row>
      <b-col class="mt-5 text-center">
        <h2 v-if="tabIndex === 0">
          NPS Proceso de reserva Web y Center
        </h2>
        <h2 v-else-if="tabIndex === 1">
          Evolución NPS Global últimos 30 días
        </h2>
        <h2 v-else-if="tabIndex === 2">
          Evolución NPS Global últimas 8 semanas
        </h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-tabs
          v-model="tabIndex"
          :fill="true"
          content-class="mt-5"
        >
          <!-- Slide 1 -->
          <b-tab title="Panel 1" active>
            <panel-chart1
              v-if="tabIndex === 0"
              :chart-data="chart1"
              :chart-options="globalOptions"
            />
          </b-tab>

          <!-- Slide 2 -->
          <b-tab title="Panel 2">
            <panel-chart2
              v-if="tabIndex === 1"
              :chart-data="chart2.series"
              :chart-options="chart2.options"
            />
          </b-tab>

          <!-- Slide 3 -->
          <b-tab title="Panel 3">
            <panel-chart3
              v-if="tabIndex === 2"
              :chart-data="chart3.series"
              :chart-options="chart3.options"
            />
          </b-tab>

        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { loginMixin } from '@/loginMixin'
  import { npsMixin } from '@/npsMixin'
  import Logout from '@/components/Logout'
  import PanelChart1 from '@/components/Chart1'
  import PanelChart2 from '@/components/Chart2'
  import PanelChart3 from '@/components/Chart3'

  export default {
    name: 'Iberostar',
    mixins: [ npsMixin, loginMixin ],
    components: {
      Logout,
      PanelChart1,
      PanelChart2,
      PanelChart3
    },
    data() {
      return {
        //interval: null,
        intervalTabChange: null,
        tabIndex: 0,
        tab2chart1: null,
        chartLabels: ['Promotores', 'Pasivos', 'Detractores'],
        /*chart2options: {
          chart: {
            toolbar: {
              show: false
            }
          },
          dataLabels: {
            enabled: true
          },
          colors: ['#67BAAF', '#008FFB'],
          stroke: {
            width: [4, 0]
          },
          legend: {

            fontSize: '18px',
            itemMargin: {
              horizontal: 20,
              vertical: 5
            },
          },
          xaxis: {
            categories: ['Día 30','Día 29','Día 28','Día 27','Día 26','Día 25','Día 24','Día 23','Día 22','Día 21','Día 20','Día 19','Día 18','Día 17','Día 16','Día 15','Día 14','Día 13','Día 12','Día 11','Día 10','Día 9','Día 8','Día 7','Día 6','Día 5','Día 4','Día 3','Día 2','Día 1']
          },
          yaxis: [
            {
              labels: {
                style: {
                  fontSize: '16px',
                  color: '#67BAAF',
                }
              },
              title: {
                text: 'NPS',
                style: {
                  fontSize: '16px',
                  color: '#67BAAF'
                }
              },
              max: 100
            },
            {
              opposite: true,
              labels: {
                style: {
                  fontSize: '16px',
                  color: '#008FFB',
                }
              },
              title: {
                text: 'Encuestas',
                style: {
                  fontSize: '16px',
                  color: '#008FFB'
                }
              },
              max: 300
            }
          ],
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 10
          },
          responsive: [
            {
              breakpoint: 2300,
              options: {
                chart: {
                  height: 800
                },
                markers: {
                  size: 9
                },
                dataLabels: {
                  style: {
                    fontSize: '21px',
                  }
                }
              }
            },
            {
              breakpoint: 1800,
              options: {
                chart: {
                  height: 600
                },
                markers: {
                  size: 6
                },
                dataLabels: {
                  style: {
                    fontSize: '16px',
                  }
                }
              }
            }
          ]
        },
        chart2series: [],*/
        //
        chart1: {
          block1: {
            nps: 0,
            npsyear: 0,
            answers: 0,
            surveys: 0,
            series: [0,0,0]
          },
          block2: {
            nps: 0,
            npsyear: 0,
            answers: 0,
            surveys: 0,
            series: [0,0,0]
          },
          block3: {
            nps: 0,
            npsyear: 0,
            answers: 0,
            surveys: 0,
            series: [0,0,0]
          }
        },
        //
        chart2: {
          series: [
            {
              name: "Series 1",
              data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
            },
            {
              name: "Series 2",
              data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
            }
          ]
        },
        //
        chart3: {
          series: [
            {
              name: "Series 1",
              data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
            },
            {
              name: "Series 2",
              data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
            }
          ]
        }
      }
    },
    watch: {
      tabIndex() {
        this.initialize()
      }
    },
    computed: {
      globalOptions() {
        return {
          legend: {
            show: true,
            floating: true,
            fontSize: '24px',
            offsetX: 320,
            offsetY: 20,
            labels: {
              useSeriesColors: true,
            },
            itemMargin: {
              horizontal: 6,
            },
            markers: {
              width: 0
            },
            formatter: function (seriesName, opts) {
              return '<span style="opacity:0.65;font-size:80%">' + seriesName + ':</span> <strong>' + opts.w.globals.series[opts.seriesIndex] + '</strong>%'
            }
          },
          stroke: {
            lineCap: 'round'
          },
          plotOptions: {
            radialBar: {
              offsetY: -40,
              startAngle: 0,
              endAngle: 360,
              track: {
                show: true,
                strokeWidth: '50%',
                margin: 10
              },
              dataLabels: {
                show: false
              }
            }
          },
          labels: this.chartLabels,
          colors: ['#67BAAF', '#FFA726', '#E5554F'],
          responsive: [
            {
              breakpoint: 2300,
              options: {
                legend: {
                  fontSize: '28px',
                  offsetX: 140,
                  offsetY: 180
                }
              }
            },
            {
              breakpoint: 1800,
              options: {
                legend: {
                  fontSize: '26px',
                  offsetX: 140,
                  offsetY: 160
                }
              }
            },
            {
              breakpoint: 1600,
              options: {
                legend: {
                  fontSize: '18px',
                  offsetX: 90,
                  offsetY: 120
                }
              }
            },
            {
              breakpoint: 1300,
              options: {
                legend: {
                  fontSize: '18px',
                  offsetX: 70,
                  offsetY: 90
                }
              }
            }
          ]
        }
      }
    },
    async mounted() {
      await this.initialize()
      //if (process.env.NODE_ENV !== 'development') {
        this.initializeTabChange()
      //}
    },
    methods: {
      initializeTabChange () {
        this.intervalTabChange = setInterval(() => {
          clearInterval(this.intervalTabChange)
          if (this.tabIndex === 0) {
            this.tabIndex = 1
          } else if (this.tabIndex === 1) {
            this.tabIndex = 2
          } else {
            this.tabIndex = 0
          }
          this.initializeTabChange()
        }, 1000 * 30)
      },
      async initialize () {
        this.resetCharts()
        await this.loadChartDataTab(this.tabIndex)
      },
      resetCharts () {
        this.chart1 = {
          block1: {
            nps: 0,
            npsyear: 0,
            answers: 0,
            surveys: 0,
            series: [0,0,0]
          },
          block2: {
            nps: 0,
            npsyear: 0,
            answers: 0,
            surveys: 0,
            series: [0,0,0]
          },
          block3: {
            nps: 0,
            npsyear: 0,
            answers: 0,
            surveys: 0,
            series: [0,0,0]
          }
        },
        this.chart2 = {
          series: [],
          options: {
            chart: {
              toolbar: {
                show: false
              }
            },
            dataLabels: {
              enabled: true
            },
            colors: ['#008FFB', '#67BAAF'],
            fill: {
              opacity: [0, 0.2],
              type: ['gradient', 'solid'],
              gradient: {
                shade: 'light',
                type: "vertical",
                shadeIntensity: 0.5,
                gradientToColors: undefined,
                inverseColors: false,
                opacityFrom: 0,
                opacityTo: 0.75,
                stops: [0, 100],
                colorStops: []
              },
            },
            stroke: {
              width: [0, 3]
            },
            legend: {
              fontSize: '18px',
              itemMargin: {
                horizontal: 20,
                vertical: 15
              },
            },
            xaxis: {
              categories: ['Día 30','Día 29','Día 28','Día 27','Día 26','Día 25','Día 24','Día 23','Día 22','Día 21','Día 20','Día 19','Día 18','Día 17','Día 16','Día 15','Día 14','Día 13','Día 12','Día 11','Día 10','Día 9','Día 8','Día 7','Día 6','Día 5','Día 4','Día 3','Día 2','Día 1']
            },
            yaxis: [
              {
                opposite: true,
                labels: {
                  style: {
                    fontSize: '16px',
                    color: '#008FFB',
                  }
                },
                title: {
                  text: 'Encuestas',
                  style: {
                    fontSize: '16px',
                    color: '#008FFB'
                  }
                },
                max: 600
              },
              {
                labels: {
                  style: {
                    fontSize: '16px',
                    color: '#67BAAF',
                  }
                },
                title: {
                  text: 'NPS',
                  style: {
                    fontSize: '16px',
                    color: '#67BAAF'
                  }
                },
                max: 100
              }
            ],
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            markers: {
              size: 10
            },
            responsive: [
              {
                breakpoint: 2300,
                options: {
                  chart: {
                    height: 800
                  },
                  markers: {
                    size: 9
                  },
                  dataLabels: {
                    style: {
                      fontSize: '21px',
                    }
                  }
                }
              },
              {
                breakpoint: 1800,
                options: {
                  chart: {
                    height: 600
                  },
                  markers: {
                    size: 6
                  },
                  dataLabels: {
                    style: {
                      fontSize: '16px',
                    }
                  }
                }
              }
            ]
          }
        },
        this.chart3 = {
          series: [],
          options: {
            chart: {
              toolbar: {
                show: false
              }
            },
            dataLabels: {
              enabled: true
            },
            colors: ['#008FFB', '#67BAAF'],
            fill: {
              opacity: [0, 0.2],
              type: ['gradient', 'solid'],
              gradient: {
                shade: 'light',
                type: "vertical",
                shadeIntensity: 0.5,
                gradientToColors: undefined,
                inverseColors: false,
                opacityFrom: 0,
                opacityTo: 0.75,
                stops: [0, 100],
                colorStops: []
              },
            },
            stroke: {
              width: [0, 3]
            },
            legend: {
              fontSize: '18px',
              itemMargin: {
                horizontal: 20,
                vertical: 15
              },
            },
            xaxis: {
              categories: ['Semana 8','Semana 7','Semana 6','Semana 5','Semana 4','Semana 3','Semana 2','Semana 1']
            },
            yaxis: [
              {
                opposite: true,
                labels: {
                  style: {
                    fontSize: '16px',
                    color: '#008FFB',
                  }
                },
                title: {
                  text: 'Encuestas',
                  style: {
                    fontSize: '16px',
                    color: '#008FFB'
                  }
                },
                max: 1000
              },
              {
                labels: {
                  style: {
                    fontSize: '16px',
                    color: '#67BAAF',
                  }
                },
                title: {
                  text: 'NPS',
                  style: {
                    fontSize: '16px',
                    color: '#67BAAF'
                  }
                },
                max: 100
              }
            ],
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            markers: {
              size: 10
            },
            responsive: [
              {
                breakpoint: 2300,
                options: {
                  chart: {
                    height: 800
                  },
                  markers: {
                    size: 9
                  },
                  dataLabels: {
                    style: {
                      fontSize: '21px',
                    }
                  }
                }
              },
              {
                breakpoint: 1800,
                options: {
                  chart: {
                    height: 600
                  },
                  markers: {
                    size: 6
                  },
                  dataLabels: {
                    style: {
                      fontSize: '16px',
                    }
                  }
                }
              }
            ]
          }
        }
      },
      async loadChartDataTab (tabindex) {
        if (tabindex === 0) {
          await this.loadChartData(1, 1, 8626, 'apiGetNPSData')
          await this.loadChartData(1, 2, 8627, 'apiGetNPSData')
          await this.loadChartData(1, 3, 8882, 'apiGetNPSData')
        } else if (tabindex === 1) {
          await this.loadChartData(2, 1, 8626, 'apiGetNPSDataLast30Days')
        } else if (tabindex === 2) {
          await this.loadChartData(3, 1, 8626, 'apiGetNPSDataLast8Weeks')
        }
      },
      async loadChartData (tab, chart, geo_id, endpoint) {
        // Tab 1
        if (tab === 1) {
          await this.livepanelLoadData({
            geo_id: geo_id,
            center_id: '',
            campaign_id: '',
            wave_id: '',
            tab: tab,
            chart: chart,
            endpoint: endpoint
          })
          let dataArray = []
          const total_promoters_pctg = parseFloat(this.$store.state.livepaneldata[`tab${tab}chart${chart}`].total_promoters_pctg)
          const total_passives_pctg = parseFloat(this.$store.state.livepaneldata[`tab${tab}chart${chart}`].total_passives_pctg)
          const total_detractors_pctg = parseFloat(this.$store.state.livepaneldata[`tab${tab}chart${chart}`].total_detractors_pctg)
          dataArray.push(total_promoters_pctg)
          dataArray.push(total_passives_pctg)
          dataArray.push(total_detractors_pctg)
          if (total_promoters_pctg) {
            this.chart1[`block${chart}`].series = dataArray
          } else {
            this.chart1[`block${chart}`].series = ['------','------','------']
          }
          this.chart1[`block${chart}`].nps = this.$store.state.livepaneldata[`tab${tab}chart${chart}`].total_nps
          this.chart1[`block${chart}`].npsyear = this.$store.state.livepaneldata[`tab${tab}chart${chart}`].curyear_nps
          this.chart1[`block${chart}`].surveys = this.$store.state.livepaneldata[`tab${tab}chart${chart}`].total_surveys
        }
        // Tab 2
        if (tab === 2) {
          await this.livepanelLoadData({
            geo_id: geo_id,
            center_id: '',
            campaign_id: '',
            wave_id: '',
            tab: tab,
            chart: chart,
            endpoint: endpoint
          })
          let npsArray = []
          let qtyArray = []
          let labelsArray = []
          for (let i = 0; i < 30; i++) {
            npsArray.push(parseFloat(this.$store.state.livepaneldata[`tab2chart1`][`nps_${i}`]))
            qtyArray.push(parseFloat(this.$store.state.livepaneldata[`tab2chart1`][`surveys_${i}`]))
            labelsArray.push(`Dia ${i}`)
          }
          this.chart2.series = [
            {
              name: 'Encuestas',
              type: 'bar',
              data: qtyArray
            },
            {
              name: 'NPS',
              type: 'area',
              data: npsArray
            }
          ]
          this.chart2.labels = labelsArray
        }
        // Tab 3
        if (tab === 3) {
          await this.livepanelLoadData({
            geo_id: geo_id,
            center_id: '',
            campaign_id: '',
            wave_id: '',
            tab: tab,
            chart: chart,
            endpoint: endpoint
          })
          let npsArray = []
          let qtyArray = []
          let labelsArray = []
          for (let i = 0; i < 8; i++) {
            npsArray.push(parseFloat(this.$store.state.livepaneldata[`tab3chart1`][`nps_${i}`]))
            qtyArray.push(parseFloat(this.$store.state.livepaneldata[`tab3chart1`][`surveys_${i}`]))
            labelsArray.push(`Dia ${i}`)
          }
          this.chart3.series = [
            {
              name: 'Encuestas',
              type: 'bar',
              data: qtyArray
            },
            {
              name: 'NPS',
              type: 'area',
              data: npsArray
            }
          ]
          this.chart3.labels = labelsArray
        }
      }
    }
  }
</script>

<style>
  .nav-tabs a.nav-link {
    color: #67BAAF !important;
  }
  h1 {
    color: #67BAAF;
    font-weight: bold;
    line-height: 0.75;
  }
  h1 span,
  h4 span {
    font-weight: normal;
    opacity: 0.5;
  }
  h2 {
    color: #333;
    font-weight: bold;
  }
  h3 {
    color: #67BAAF;
    font-size: 21px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 0.95;
  }
  h4 {
    color: #67BAAF;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  h5 {
    color: #67BAAF;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
</style>
