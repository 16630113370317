<template>
  <b-row>
    <b-col>
      <apexchart
        type="area"
        :options="chartOptions"
        :series="chartData"
      />
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: 'Chart2',
    props: {
      chartData: {
        type: Array,
        required: true
      },
      chartOptions: {
        type: Object,
        required: true
      }
    }
  }
</script>
